import { useEffect } from 'react';
const TagManager = require('react-gtm-module');

const useGTM = () => {

  const tagManagerArgs = {
      gtmId: 'GTM-WNL47NL'
  }

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  });

};

export default useGTM;
