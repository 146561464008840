import React, { ReactElement } from 'react';
import './Main.scss';
import { useTranslation } from 'react-i18next';
import Register from '../../components/Register/Register';
import Postcard from '../../components/Postcard/Postcard';
import Rules from '../../components/Rules/Rules';
import Winners from '../../components/Winners/Winners';

const Main: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="Main">
      <section id="register-section">
        <Register />
      </section>
      <section id="postcard-section">
        <Postcard />
      </section>
      <section id="rules-section">
        <Rules />
      </section>
      <section id="winners-section">
        <Winners />
      </section>
    </div>
  );
};

export default Main;
