import React, { ReactElement } from 'react';
import axios from 'axios';

const EmailLanding: React.FC = (): ReactElement => {

  // Get reference from query param
  function GetQueryParam() {
    const queryParams = new URLSearchParams(window.location.search);
    const get_value = queryParams.get("value");

    // Send info to backend
    const data = { id: get_value };
    axios
      .post('/api/handle_incoming', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      // Redirect to main page
      .then((res) => {
        if (res.data.IsValid) {
          window.location.replace("/")
        } else {
          console.log("data not valid");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
      
    return <>Teid suunatakse koheselt ümber. Kui see ei toimi, vajutage <a href="/">siia</a></>;
  }

  return GetQueryParam();
};

export default EmailLanding;
