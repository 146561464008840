import React, { FormEvent, ReactElement, useState } from 'react';
import i18n from 'i18next';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import _ from 'lodash';
// Component imports
import Loading from '../loading/Loading';
import './Postcard.scss';
// import Swiper core and required modules
import { Navigation, Pagination, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import Kaart1 from '../../assets/images/kaart_1.jpg';
import Kaart2 from '../../assets/images/kaart_2.jpg';
import Kaart3 from '../../assets/images/kaart_3.jpg';
import Kaart4 from '../../assets/images/kaart_4.jpg';
import Kaart5 from '../../assets/images/kaart_5.jpg';
import Kaart6 from '../../assets/images/kaart_6.jpg';

import { useTranslation } from 'react-i18next';

const Postcard: React.FC = (): ReactElement => {
  type CardType =
    | typeof Kaart1
    | typeof Kaart2
    | typeof Kaart3
    | typeof Kaart4
    | typeof Kaart5
    | typeof Kaart6;

  type ValidationErrors = {
    name: { state: boolean; message: string };
    email: { state: boolean; message: string };
    message: { state: boolean; message: string };
  };

  const { t } = useTranslation();

  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

  const [SelectedCard, setSelectedCard] = useState<CardType>(Kaart1);

  const [SelectedCardNumber, setSelectedCardNumber] = useState<number>(1);

  const [EmailVisible, setEmailVisible] = useState<boolean>(false);

  const [EmailSuccess, setEmailSuccess] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const [errors, setValidationErrors] = useState<ValidationErrors>({
    name: { state: false, message: '' },
    email: { state: false, message: '' },
    message: { state: false, message: '' },
  });

  const submitEmail = (event: FormEvent) => {
    event.preventDefault();
    // console.log('campaign is over');
    setLoading(true);
    const myForm = document.getElementById('email-form') as HTMLFormElement;
    const formData = new FormData(myForm);
    // console.log(formData);
    formData.append('lang', getLanguage());
    formData.append('card', SelectedCardNumber.toString());

    // formData.forEach((entries) => console.log(entries));
    axios
      .post('/api/submit/email', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        setLoading(false);
        if (res.data.IsValid) {
          setEmailSuccess(true);

          setValidationErrors({
            name: { state: false, message: '' },
            email: { state: false, message: '' },
            message: { state: false, message: '' },
          });
          const resetForm = event.target as HTMLFormElement;
          resetForm.reset();
        } else {
          setValidationErrors({
            name: { state: false, message: '' },
            email: { state: false, message: '' },
            message: { state: false, message: '' },
          });

          if (!_.isEmpty(res.data.Errors)) {
            const err = res.data.Errors;
            // console.log(err.code);
            setValidationErrors({
              name: _.isEmpty(err.name)
                ? { state: false, message: '' }
                : { state: true, message: err.name.msg },
              email: _.isEmpty(err.email)
                ? { state: false, message: '' }
                : { state: true, message: err.email.msg },
              message: _.isEmpty(err.message)
                ? { state: false, message: '' }
                : { state: true, message: err.message.msg },
            });
            // console.log(response.data.errors);
          }
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <div className="Postcard">
      <div className="swiper-container">
        <div className="text-div">
          {/* <h2>HAIKUGA E-KAART</h2> */}
          <p>
            <span>Saada haikuga e-kaart ja</span>
            <span>tuleta sõpradele meelde, et maailm on valla!</span>
          </p>
        </div>
        <div className="swiper-wrapper">
          <Swiper
            // install Swiper modules
            modules={[Navigation, Pagination, Mousewheel]}
            spaceBetween={isMobile ? 10 : 80}
            slidesPerView={isMobile ? 1.5 : 2}
            centeredSlides
            loop
            slideToClickedSlide
            navigation={isMobile ? false : true}
            // mousewheel={{ invert: true }}
            pagination={{ clickable: true }}
            //onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={(swiper) => {
              //console.log(swiper.activeIndex);
              switch (swiper.activeIndex) {
                case 2:
                  setSelectedCard(Kaart1);
                  setSelectedCardNumber(1);
                  break;
                case 3:
                  setSelectedCard(Kaart2);
                  setSelectedCardNumber(2);

                  break;
                case 4:
                  setSelectedCard(Kaart3);
                  setSelectedCardNumber(3);

                  break;
                case 5:
                  setSelectedCard(Kaart4);
                  setSelectedCardNumber(4);

                  break;
                case 6:
                  setSelectedCard(Kaart5);
                  setSelectedCardNumber(5);

                  break;
                case 7:
                  setSelectedCard(Kaart6);
                  setSelectedCardNumber(6);

                  break;
                default:
                  setSelectedCard(Kaart1);
                  setSelectedCardNumber(1);

                  break;
              }
            }}
          >
            <SwiperSlide>
              <button
                onClick={() => {
                  setSelectedCard(Kaart1);
                  setSelectedCardNumber(1);
                }}
              >
                <img src={Kaart1} alt="" />
              </button>
            </SwiperSlide>
            <SwiperSlide>
              <button
                onClick={() => {
                  setSelectedCard(Kaart2);
                  setSelectedCardNumber(2);
                }}
              >
                <img src={Kaart2} alt="" />
              </button>
            </SwiperSlide>
            <SwiperSlide>
              <button
                onClick={() => {
                  setSelectedCard(Kaart3);
                  setSelectedCardNumber(3);
                }}
              >
                <img src={Kaart3} alt="" />
              </button>
            </SwiperSlide>
            <SwiperSlide>
              <button
                onClick={() => {
                  setSelectedCard(Kaart4);
                  setSelectedCardNumber(4);
                }}
              >
                <img src={Kaart4} alt="" />
              </button>
            </SwiperSlide>
            <SwiperSlide>
              <button
                onClick={() => {
                  setSelectedCard(Kaart5);
                  setSelectedCardNumber(5);
                }}
              >
                <img src={Kaart5} alt="" />
              </button>
            </SwiperSlide>
            <SwiperSlide>
              <button
                onClick={() => {
                  setSelectedCard(Kaart6);
                  setSelectedCardNumber(6);
                }}
              >
                <img src={Kaart6} alt="" />
              </button>
            </SwiperSlide>
          </Swiper>
        </div>
        <a
          className="email-nav-btn"
          href="#mail-div"
          onClick={() => setEmailVisible(true)}
        >
          VALI KAART
        </a>
      </div>

      <div
        id="mail-div"
        className={'mail-container ' + (EmailVisible ? '' : 'hidden')}
      >
        <Loading loading={loading} />
        <button
          className="close-btn"
          onClick={() => {
            setEmailVisible(false);
          }}
        >
          <span className="first"></span>
          <span className="second"></span>
        </button>
        <img src={SelectedCard} alt="" />
        <div className="email-form-wrapper">
          <form
            onSubmit={submitEmail}
            id="email-form"
            className={EmailSuccess ? 'hidden' : ''}
          >
            <div className="field">
              <div className="control">
                <input
                  className="input"
                  type="text"
                  name="name"
                  placeholder="Saatja nimi"
                />
              </div>
              {errors.name.state && (
                <p className="help is-danger">
                  {t('validation.' + errors.name.message)}
                </p>
              )}
            </div>
            <div className="field">
              <div className="control">
                <input
                  className="input"
                  type="email"
                  name="email"
                  placeholder="Saaja e-mail"
                />
              </div>
              {errors.email.state && (
                <p className="help is-danger">
                  {t('validation.' + errors.email.message)}
                </p>
              )}
            </div>
            <div className="field">
              <div className="control">
                <textarea
                  className="textarea"
                  name="message"
                  placeholder="Personaalne sõnum..."
                ></textarea>
              </div>
              {errors.message.state && (
                <p className="help is-danger">
                  {t('validation.' + errors.message.message)}
                </p>
              )}
            </div>
            <button type="submit" className="btn black">
              SAADA
            </button>
            {/* <span className="campaign-over">
              Kampaania on lõppenud, täname kõiki osalejaid
            </span> */}
          </form>
          <div className={'email-success ' + (EmailSuccess ? '' : 'hidden')}>
            <span>Sinu kaart on saadetud!</span>
            <a
              className="email-nav-btn"
              href="#top"
              onClick={() => {
                setEmailVisible(false);
                setEmailSuccess(false);
              }}
            >
              AVALEHELE
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Postcard;
