import React, { FormEvent, ReactElement, useState } from 'react';
import './Register.scss';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import axios from 'axios';
import _ from 'lodash';

// Component imports
import Loading from '../loading/Loading';

import './Register.scss';
import Logo from '../../assets/images/geisha.png';
import Estravel from '../../assets/images/estravel.png';
import Box from '../../assets/images/box.png';
import Candy from '../../assets/images/Geisha_Komm_Slogan.png';
// import Candy from '../../assets/images/candy.png';
import Flower_1 from '../../assets/images/flower_1.png';
import Flower_3 from '../../assets/images/flower_3.png';
import Flower_3_Cropped from '../../assets/images/flower_3_cropped.png';
import Flower_5 from '../../assets/images/flower_5_cropped.png';
import Video from '../../assets/video/vid_desktop.mp4';
import VideoMobile from '../../assets/video/vid_mobile.mp4';

const Register: React.FC = (): ReactElement => {
  type ValidationErrors = {
    First_Name: { state: boolean; message: string };
    Last_Name: { state: boolean; message: string };
    Email: { state: boolean; message: string };
    Phone: { state: boolean; message: string };
    Code: { state: boolean; message: string };
    Privacy: { state: boolean; message: string };
  };

  const { t } = useTranslation();

  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

  const [loading, setLoading] = useState<boolean>(false);

  const [RegVisible, setRegVisible] = useState<boolean>(false);

  const [RegSuccess, setRegSuccess] = useState<boolean>(false);

  const [errors, setValidationErrors] = useState<ValidationErrors>({
    First_Name: { state: false, message: '' },
    Last_Name: { state: false, message: '' },
    Email: { state: false, message: '' },
    Phone: { state: false, message: '' },
    Code: { state: false, message: '' },
    Privacy: { state: false, message: '' },
  });

  const submitForm = (event: FormEvent) => {
    event.preventDefault();
    // console.log('campaign is over');
    setLoading(true);
    const myForm = document.getElementById('register-form') as HTMLFormElement;
    const formData = new FormData(myForm);
    // formData.forEach(entries => console.log(entries));
    formData.append('lang', getLanguage());
    // console.log(typeof formData);
    axios
      .post('/api/submit/new', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        // console.log(res.data);
        setLoading(false);
        if (res.data.IsValid) {
          const resetForm = event.target as HTMLFormElement;
          resetForm.reset();
          setRegSuccess(true);
        } else {
          // console.log(res.data.Errors);
          setValidationErrors({
            First_Name: { state: false, message: '' },
            Last_Name: { state: false, message: '' },
            Email: { state: false, message: '' },
            Phone: { state: false, message: '' },
            Code: { state: false, message: '' },
            Privacy: { state: false, message: '' },
          });

          if (!_.isEmpty(res.data.Errors)) {
            const err = res.data.Errors;
            // console.log(err.code);
            setValidationErrors({
              Code: _.isEmpty(err.code)
                ? { state: false, message: '' }
                : { state: true, message: err.code[0].message },
              Email: _.isEmpty(err.email)
                ? { state: false, message: '' }
                : { state: true, message: err.email[0].message },
              First_Name: _.isEmpty(err.first_name)
                ? { state: false, message: '' }
                : { state: true, message: err.first_name[0].message },
              Last_Name: _.isEmpty(err.last_name)
                ? { state: false, message: '' }
                : { state: true, message: err.last_name[0].message },
              Phone: _.isEmpty(err.phone)
                ? { state: false, message: '' }
                : { state: true, message: err.phone[0].message },
              Privacy: _.isEmpty(err.privacy)
                ? { state: false, message: '' }
                : { state: true, message: err.privacy[0].message },
            });
            // console.log(response.data.errors);
          }
        }
      })
      .catch(function (error) {
        setLoading(false);
        // console.log(error);
      });
  };

  let CampaignOver = true;
  switch (getLanguage()) {
    case 'et':
      CampaignOver = true;
      break;
    default:
      CampaignOver = true;
      break;
  }

  return (
    <div className="Register">
      <div className="header" id="top">
        <div className="video-container">
          <video autoPlay playsInline muted loop className="hidden-mobile">
            <source src={Video} type="video/mp4" />
          </video>
          <video autoPlay playsInline muted loop className="hidden-desktop">
            <source src={VideoMobile} type="video/mp4" />
          </video>
          <img src={Flower_1} alt="" className="flowers flower-1" />
          <img
            src={Flower_3}
            alt=""
            className="flowers flower-3 hidden-mobile"
          />
          <img
            src={Flower_3_Cropped}
            alt=""
            className="flowers flower-3 hidden-desktop"
          />
          <img
            src={Flower_5}
            alt=""
            className="flowers flower-5 hidden-desktop"
          />
        </div>
        <div className="columns container">
          <img
            src={Flower_5}
            alt=""
            className="flowers flower-5 hidden-mobile"
          />

          <div className="column text-col is-half">
            <div className="columns logo-columns">
              <div className="column is-narrow is-narrow-mobile logo">
                <img src={Logo} alt="geisha logo" />
              </div>
            </div>
            <div className="mobile-candybox-wrapper">
              <img
                className="candy-box hidden-desktop"
                src={Box}
                alt="heart shaped box of geisha candy"
              />
            </div>

            <div className="text">
              <h1>
                KUHU <span className="bold">SÜDA</span> KUTSUB?
              </h1>
              <p className="description">
                Osta mis tahes Geisha toode, registreeri ostutšekk{' '}
                <span className="bold">geisha.ee</span> lehel ja võid võita{' '}
                <span className="megabold">
                  4 x 500€ <span className="smaller">airBaltic reisiraha</span>
                </span>
              </p>
              <p className="period">Kampaaniaperiood: 1.–29.02.24</p>
            </div>
            <a
              className="register-nav-btn"
              href="#register-div"
              onClick={() => setRegVisible(true)}
            >
              REGISTREERI
            </a>
          </div>
          <div className="column image-col hidden-mobile">
            <img
              className="candy-box hidden-mobile"
              src={Box}
              alt="heart shaped box of geisha candy"
            />
            <div className="candy-wrapper hidden-mobile">
              <img src={Candy} alt="geisha candy" />
              {/* <p>
                MÄRKA <span className="bold">HEAD</span>
              </p> */}
            </div>
          </div>
        </div>
      </div>
      <div
        id="register-div"
        className={'reg-form ' + (RegVisible ? '' : 'hidden')}
      >
        <Loading loading={loading} />
        <button
          className="close-btn"
          onClick={() => {
            setRegVisible(false);
          }}
        >
          <span className="first"></span>
          <span className="second"></span>
        </button>
        <form
          onSubmit={submitForm}
          id="register-form"
          className={RegSuccess ? 'hidden' : ''}
        >
          <h2>SISESTA OMA ANDMED</h2>
          <p>
            Hoia ostutšekk alles! Kampaaniareeglid{' '}
            <a href="#rules-section">SIIN</a>
          </p>
          <div className="field">
            <div className="control">
              <input
                className="input"
                type="text"
                name="first_name"
                placeholder="Eesnimi"
              />
            </div>
            {errors.First_Name.state && (
              <p className="help is-danger">
                {t('validation.' + errors.First_Name.message)}
              </p>
            )}
          </div>
          <div className="field">
            <div className="control">
              <input
                className="input"
                type="text"
                name="last_name"
                placeholder="Perekonnanimi"
              />
            </div>
            {errors.Last_Name.state && (
              <p className="help is-danger">
                {t('validation.' + errors.Last_Name.message)}
              </p>
            )}
          </div>
          <div className="field">
            <div className="control">
              <input
                className="input"
                type="text"
                name="email"
                placeholder="Email"
              />
            </div>
            {errors.Email.state && (
              <p className="help is-danger">
                {t('validation.' + errors.Email.message)}
              </p>
            )}
          </div>
          <div className="field">
            <div className="control">
              <input
                className="input"
                type="text"
                name="phone"
                placeholder="Telefon"
              />
            </div>
            {errors.Phone.state && (
              <p className="help is-danger">
                {t('validation.' + errors.Phone.message)}
              </p>
            )}
          </div>
          <div className="field">
            <div className="control">
              <input
                className="input"
                type="text"
                name="code"
                placeholder="Ostutšeki number"
              />
            </div>
            {errors.Code.state && (
              <p className="help is-danger">
                {t('validation.' + errors.Code.message)}
              </p>
            )}
          </div>
          <label className="checkbox">
            <input name="privacy" type="checkbox" id="rules-check" />
            Nõustun isikuandmete edastamisega
          </label>
          {errors.Privacy.state && (
            <p className="help is-danger">
              {t('validation.' + errors.Privacy.message)}
            </p>
          )}
          {/* SUBMIT BUTTON */}
          {CampaignOver ? (
            <span className="campaign-over">
              Kampaania on lõppenud, täname kõiki osalejaid
            </span>
          ) : (
            <button type="submit" className="btn black">
              REGISTREERI
            </button>
          )}
        </form>
        <div className={'reg-success ' + (RegSuccess ? '' : 'hidden')}>
          <span>Aitäh! Osaled loosimises.</span>
          <a
            className="register-nav-btn"
            href="#top"
            onClick={() => {
              setRegVisible(false);
              setRegSuccess(false);
            }}
          >
            AVALEHELE
          </a>
        </div>
      </div>
    </div>
  );
};

export default Register;
