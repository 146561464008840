import React, { ReactElement, useEffect, useState } from 'react';
import './Winners.scss';
import Flower from '../../assets/images/bottom_flower.png';
import _ from 'lodash';
import axios from 'axios';

interface PrizeList {
  kinkekaart: Pick<any, string | number | symbol>[];
}

const Winners: React.FC = (): ReactElement => {
  const [winners, setWinners] = useState<PrizeList>({
    kinkekaart: [],
  });

  const getWinners = async () => {
    const res = await axios.get('/api/dataservice/winners/');
    // console.log(res);
    const prizes = _.mapValues(_.groupBy(res.data, 'prize'), (plist) =>
      plist.map((prize) => _.omit(prize, 'prize'))
    );

    setWinners({
      kinkekaart: prizes.kinkekaart === undefined ? [] : prizes.kinkekaart,
    });
  };

  useEffect(() => {
    getWinners();
  }, []);

  return (
    <div className="Winners container">
      <h2>VÕITJAD</h2>
      <ul>
        {/*winners.kinkekaart.map(function (item: any, index: any) {
          return <li key={index}>{item.first_name + ' ' + item.last_name}</li>;
        })*/}
      </ul>
      <img src={Flower} alt="pink flower" />
    </div>
  );
};

export default Winners;
