import React, { ReactElement } from 'react';
import './App.scss';
import { Route, Routes } from 'react-router-dom';
import usePageTracking from './components/page-tracking/usePageTracking';
import useGTM from './components/gtm/gtm';

import Main from './views/Main/Main';
import Landing from './views/EmailLanding/EmailLanding';
import Placeholder from './views/Placeholder/Placeholder';

const App: React.FC = (): ReactElement => {
  usePageTracking();
  useGTM();

  return (
    <div className="App">
      {/* <Main /> */}
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/landing" element={<Landing />} />
      </Routes>
    </div>
  );
};

export default App;
