import React, { ReactElement, useState } from 'react';
import './Rules.scss';

const Rules: React.FC = (): ReactElement => {
  const [RulesVisible, setRulesVisible] = useState<boolean>(true);

  return (
    <div className="Rules">
      {/* <div className="button-div">
        <a
          className="rules-nav-btn"
          href="#rules-div"
          onClick={() => setRulesVisible(true)}
        >
          KAMPAANIAREEGLID
        </a>
      </div> */}
      <div
        className={'rules-container ' + (RulesVisible ? '' : 'hidden')}
        id="rules-div"
      >
        {/* <button
          className="close-btn"
          onClick={() => {
            setRulesVisible(false);
          }}
        >
          <span className="first"></span>
          <span className="second"></span>
        </button> */}
        <div className="rules-wrapper">
          <h2>KAMPAANIAREEGLID</h2>
          <ol>
            <li>
              Kampaania{' '}
              <span className="bold">“Geisha - Kuhu süda kutsub? ”</span> on
              alates <span className="bold">01.02.2024</span> kuni{' '}
              <span className="bold">29.02.2024</span> toimuv tarbijamäng
              (edaspidi kampaania), mida korraldab Fazer Eesti OÜ, (asukoht:
              Pärnu maantee 158/1, Tallinn; edaspidi korraldaja).
            </li>
            <li>
              Kampaania auhinnafondi{' '}
              <span className="bold">kuulub AirBaltic kinkekaardid 4x500€</span>
              , mis loositakse välja 01.03.2024. Auhinnafondi koguväärtus on
              2000€. Air Baltic Corporation AS,.registrikood 40003245752,
              aadress: Tehnikas iela 3 International Airport RIGA, Marupe
              district Läti-1053 Täpsem info kinkekaardi kohta AirBaltic
              veebilehel:{' '}
              <a
                href="https://www.airbaltic.com/et/kinkekaardid"
                target="_blank"
                rel="noreferrer"
              >
                https://www.airbaltic.com/et/kinkekaardid{' '}
              </a>
              või{' '}
              <a
                href="https://www.airbaltic.com/en/gift-card"
                target="_blank"
                rel="noreferrer"
              >
                https://www.airbaltic.com/en/gift-card
              </a>
            </li>
            <li>
              Kampaania läbiviimise kord on kehtestatud korraldaja poolt
              käesolevates reeglites. Kõik korraldaja otsused kampaania
              läbiviimisel on lõplikud ning kõigile kampaanias osalejatele
              kohustuslikud.
            </li>
            <li>
              Kampaaniatooted on{' '}
              <span className="bold">
                Geisha piimašokolaad 100g, Geisha Caramel ja Sea salt
                piimašokolaad 100g, Geisha Peanut piimašokolaad 100g, Geisha
                Crunchy šokolaadibatoon 50g, Geisha kommikarp 150g, Geisha Dark
                kommikarp 150g, Geisha Caramel ja Sea salt kommikarp 150g,
                Geisha kommikarp 270g, Geisha kommikarp 225g, Geisha
                piimašokolaadikompvekid 3kg, Geisha küpsised 100g, Geisha
                piimašokolaadibatoon 37g.
              </span>{' '}
              (edaspidi tooted).
            </li>
            <li>
              Kampaanias osalemiseks tuleb osta vähemalt üks Geisha toode ja
              registreerida ostutšeki number kampaanialehel{' '}
              <a href="http://www.geisha.ee" target="_blank" rel="noreferrer">
                www.geisha.ee
              </a>
              . Kampaanias osalemine on tasuta. Auhinna kättesaamiseks tuleb
              kampaania korraldajale esitada ostutšekk.
              <ul>
                <li>
                  Loosimises osalemiseks võib ühe ostutšeki numbri esitada
                  ainult ühe korra. Sama ostutšeki mitmekordsel registreerimisel
                  osaleb ainult esimesena saabunud registreering.
                </li>
                <li className="bold">Isikuandmete töötlemise kord</li>
                <li>
                  Fazer Eesti OÜ töötleb kampaania käigus osaleja poolt esitatud
                  isikuandmeid vastavalt Eesti Vabariigis kehtivatele seadustele
                  kampaania läbiviimise eesmärgil.
                </li>
                <li>
                  Kampaanias osaleja annab Fazer Eesti OÜ-le nõusoleku töödelda
                  esitatud andmeid järgnevatel eesmärkidel:
                </li>
                <li>
                  kampaania tulemuste kohta kokkuvõtete tegemiseks; kampaaniaga
                  seotud koostööpartneritele info edastamiseks; võitjate välja
                  loosimiseks ja nende teavitamiseks; võitjate nimede
                  avaldamiseks kampaania veebilehel.
                </li>
                <li>
                  Kampaanias osalejatel on õigus saada lisainfot Fazer Eesti OÜ
                  poolt isikuandmete töötlemise kohta. Selleks tuleb saata
                  e-kiri aadressile:{' '}
                  <a href="mailto:info@fazer.ee">info@fazer.ee</a>{' '}
                </li>
                <li>
                  Isikuandmete vastutav töötleja on Fazer Eesti OÜ, registrikood
                  10057691, Pärnu maantee 158/1, Tallinn 11317; tel 6502421;
                  <a href="mailto:info@fazer.ee"> info@fazer.ee</a>
                </li>
                <li>
                  Kogutud andmeid säilitatakse kolm kuud alates kampaania
                  lõpukuupäevast ehk kuni 01.06.2024 Võitjate täielik nimekiri
                  on veebilehel üleval kuni 31.03.2024.
                </li>
              </ul>{' '}
            </li>
            <li>
              <span className="bold">01.03.2024</span> kell 12.00 loositakse
              kõikide kampaanias osalejate vahel välja{' '}
              <span className="bold">
                4x500 AirBaltic kinkekaart väärtusega 500€. Kogus 4 tükki.
                Auhinnafondi väärtus 2000€
              </span>{' '}
              <ul>
                <li>
                  Auhinna loosimises osalevad kõik veebis registreerunud isikud
                  kampaania reeglites esitatud perioodil (alates keskkonna
                  avanemisest). Võitjat informeeritakse telefoni või meili teel.
                  Loosimise juures viibib vähemalt üks Fazer Eesti OÜ esindaja.
                </li>
              </ul>
            </li>
            <li>
              Auhinna võitja nimi avaldatakse internetis aadressil{' '}
              <a href="http://www.geisha.ee" target="_blank" rel="noreferrer">
                www.geisha.ee
              </a>{' '}
              hiljemalt loosimisele järgneval kolmandal tööpäeval. Võitjaga
              võetakse korraldaja esindaja poolt ühendust andmete ja auhinna
              kättetoimetamisviisi täpsustamiseks.
            </li>
            <li>
              Juhul, kui auhinna võitja ei vasta korraldaja poolt kehtestatud
              kampaania tingimustele või kui ta ei ole alles hoidnud toote
              ostutšekki, millel oleva numbri ta edastas kampaania korraldajale
              veebi vahendusel; samuti juhul, kui auhinna võitja ei ole
              hiljemalt ühe nädala jooksul alates, tema kui auhinna võitja nime
              avalikustamist internetis aadressil{' '}
              <a href="http://www.geisha.ee" target="_blank" rel="noreferrer">
                www.geisha.ee
              </a>{' '}
              olnud veebis esitatud kontaktandmetel kättesaadav andmete
              täpsustamiseks, on korraldajal õigus jätta talle auhind üle
              andmata. Välja andmata jäänud auhind loositakse uuesti.{' '}
            </li>
            <li>
              Korraldajalt ükskõik missuguse kampaania auhinna saaja annab loa
              korraldajale kasutada võitja nime meedias, reklaamides,
              PR-kampaaniates jms üritustel ilma võitjale selle eest täiendavat
              tasu maksmata ja vastavat kokkulepet sõlmimata.
            </li>
            <li>
              Kampaania käigus kampaanias osaleja poolt võidetud auhinda ei
              asendata võitja nõudmisel teist liiki auhinnaga ega hüvitata
              võitjale auhinna maksumust rahas, v.a juhul, kui auhind
              asendatakse teise samaväärse auhinnaga korraldaja otsusel.
            </li>
            <li>
              Auhinna võitjale üleandmisega seotud riiklikud maksud tasub
              korraldaja Eesti Vabariigi seadustes sätestatud korras ja
              tähtaegadel.
            </li>
            <li>
              Kampaanias osaleja poolt kampaania tingimuste eiramise või
              korraldajale enda kohta valede või ebatäpsete andmete esitamise
              korral, samuti muude ebaõnnestumiste korral (korraldajal ei
              õnnestu auhinna võitjaga kontakti saada), mis ei ole tingitud
              korraldaja süüst, ei vastuta korraldaja kampaanias osaleja
              auhindade loosimisest või auhinna kätteandmisest kõrvalejäämise
              eest.
            </li>
            <li>
              Vääramatu jõu (force majeure) asjaolude ilmnemisel on korraldajal
              ühepoolne õigus katkestada kampaania ja jätta auhinnad välja
              loosimata ja/või auhinnad võitjatele üle andmata, teatades sellest
              koheselt kampaanias osalejatele meedia vahendusel.
            </li>
            <li>
              Kõik kampaaniast tulenevad vaidlused lahendatakse vastavalt Eesti
              Vabariigi seadusandlusele.
            </li>
            <li>
              Kõik pretensioonid seoses kampaania korraldamise ja/või
              läbiviimisega tuleb saata kirjalikult kampaania korraldaja
              asukohta: Pärnu maantee 158/1, 11317 Tallinn märksõnaga{' '}
              <span className="bold">“KAMPAANIA"</span> hiljemalt 01.03.2024.
              Kaebused, mis esitatakse peale nimetatud kuupäeva, ei võeta
              menetluse. Laekunud pretensioonidele vastatakse 3 tööpäeva
              jooksul. Kampaania lisainfo telefonil 650 2421;{' '}
              <a href="mailto:info@fazer.ee">info@fazer.ee</a>{' '}
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Rules;
